export const albums_data = {
   "animals": {
      "name": "Animals",
      "photos": [
         {
            "faces": [],
            "height": 960,
            "name": "bjAjspm.jpg",
            "slug": "bjajspm-jpg",
            "src": "/static/_gallery/albums/animals/960x960_bjajspm-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/960x960_bjajspm-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/960x960_bjajspm-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x500_bjajspm-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x800_bjajspm-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x500_bjajspm-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 1280,
            "name": "wyooZJL.jpg",
            "slug": "wyoozjl-jpg",
            "src": "/static/_gallery/albums/animals/1047x1280_wyoozjl-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x1251_wyoozjl-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1047x1280_wyoozjl-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x611_wyoozjl-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x978_wyoozjl-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x611_wyoozjl-jpg.jpg",
            "width": 1047
         }
      ],
      "slug": "animals",
      "src": "/static/_gallery/albums/animals/500x500_bjajspm-jpg.jpg"
   },
   "animals-cats": {
      "name": "animals > Cats",
      "photos": [
         {
            "faces": [],
            "height": 903,
            "name": "6kA6cmL.jpg",
            "slug": "6ka6cml-jpg",
            "src": "/static/_gallery/albums/animals-cats/700x903_6ka6cml-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/700x903_6ka6cml-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/700x903_6ka6cml-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x645_6ka6cml-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/700x903_6ka6cml-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x645_6ka6cml-jpg.jpg",
            "width": 700
         },
         {
            "faces": [],
            "height": 1080,
            "name": "A5901oY.jpg",
            "slug": "a5901oy-jpg",
            "src": "/static/_gallery/albums/animals-cats/700x1080_a5901oy-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/700x1080_a5901oy-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/700x1080_a5901oy-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x771_a5901oy-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/700x1080_a5901oy-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x771_a5901oy-jpg.jpg",
            "width": 700
         },
         {
            "faces": [],
            "height": 4000,
            "name": "Bi7ZTxF.jpg",
            "slug": "bi7ztxf-jpg",
            "src": "/static/_gallery/albums/animals-cats/1600x2133_bi7ztxf-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1365_bi7ztxf-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1600x2133_bi7ztxf-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x666_bi7ztxf-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x1066_bi7ztxf-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x666_bi7ztxf-jpg.jpg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 924,
            "name": "EE8YVsi.jpg",
            "slug": "ee8yvsi-jpg",
            "src": "/static/_gallery/albums/animals-cats/720x924_ee8yvsi-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/720x924_ee8yvsi-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/720x924_ee8yvsi-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x641_ee8yvsi-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/720x924_ee8yvsi-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x641_ee8yvsi-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 956,
            "name": "FjtRtUm.jpg",
            "slug": "fjtrtum-jpg",
            "src": "/static/_gallery/albums/animals-cats/720x956_fjtrtum-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/720x956_fjtrtum-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/720x956_fjtrtum-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x663_fjtrtum-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/720x956_fjtrtum-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x663_fjtrtum-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 1024,
            "name": "IMG-20221020-WA0001.jpg",
            "slug": "img-20221020-wa0001-jpg",
            "src": "/static/_gallery/albums/animals-cats/773x1024_img-20221020-wa0001-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/773x1024_img-20221020-wa0001-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/773x1024_img-20221020-wa0001-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x662_img-20221020-wa0001-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/773x1024_img-20221020-wa0001-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x662_img-20221020-wa0001-jpg.jpg",
            "width": 773
         },
         {
            "faces": [],
            "height": 1072,
            "name": "IMG_20220715_151743.jpg",
            "slug": "img-20220715-151743-jpg",
            "src": "/static/_gallery/albums/animals-cats/1080x1072_img-20220715-151743-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1016_img-20220715-151743-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1080x1072_img-20220715-151743-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/499x496_img-20220715-151743-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x794_img-20220715-151743-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/499x496_img-20220715-151743-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 828,
            "name": "LpiaY99.jpg",
            "slug": "lpiay99-jpg",
            "src": "/static/_gallery/albums/animals-cats/503x828_lpiay99-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/503x828_lpiay99-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/503x828_lpiay99-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x823_lpiay99-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/503x828_lpiay99-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x823_lpiay99-jpg.jpg",
            "width": 503
         },
         {
            "faces": [],
            "height": 4608,
            "name": "Qmt8R3C.jpg",
            "slug": "qmt8r3c-jpg",
            "src": "/static/_gallery/albums/animals-cats/1600x2133_qmt8r3c-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1365_qmt8r3c-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1600x2133_qmt8r3c-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x666_qmt8r3c-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x1066_qmt8r3c-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x666_qmt8r3c-jpg.jpg",
            "width": 3456
         },
         {
            "faces": [],
            "height": 1158,
            "name": "RDT_20220903_2353348126818623923875190.jpg",
            "slug": "rdt-20220903-2353348126818623923875190-jpg",
            "src": "/static/_gallery/albums/animals-cats/843x1158_rdt-20220903-2353348126818623923875190-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/843x1158_rdt-20220903-2353348126818623923875190-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/843x1158_rdt-20220903-2353348126818623923875190-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x686_rdt-20220903-2353348126818623923875190-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x1098_rdt-20220903-2353348126818623923875190-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x686_rdt-20220903-2353348126818623923875190-jpg.jpg",
            "width": 843
         },
         {
            "faces": [],
            "height": 960,
            "name": "TiFo32o.jpg",
            "slug": "tifo32o-jpg",
            "src": "/static/_gallery/albums/animals-cats/796x960_tifo32o-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/796x960_tifo32o-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/796x960_tifo32o-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x603_tifo32o-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/796x960_tifo32o-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x603_tifo32o-jpg.jpg",
            "width": 796
         },
         {
            "faces": [],
            "height": 901,
            "name": "Vuc8KRc.jpg",
            "slug": "vuc8krc-jpg",
            "src": "/static/_gallery/albums/animals-cats/720x901_vuc8krc-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/720x901_vuc8krc-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/720x901_vuc8krc-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x625_vuc8krc-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/720x901_vuc8krc-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x625_vuc8krc-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 180,
            "name": "YR6OLBT.gif",
            "slug": "yr6olbt-gif",
            "src": "/static/_gallery/albums/animals-cats/256x180_yr6olbt-gif.gif",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/256x180_yr6olbt-gif.gif"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/256x180_yr6olbt-gif.gif"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/256x180_yr6olbt-gif.gif"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/256x180_yr6olbt-gif.gif"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/256x180_yr6olbt-gif.gif",
            "width": 256
         },
         {
            "faces": [],
            "height": 301,
            "name": "YvTllrM.jpg",
            "slug": "yvtllrm-jpg",
            "src": "/static/_gallery/albums/animals-cats/720x301_yvtllrm-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/720x301_yvtllrm-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/720x301_yvtllrm-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x209_yvtllrm-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/720x301_yvtllrm-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x209_yvtllrm-jpg.jpg",
            "width": 720
         },
         {
            "faces": [],
            "height": 835,
            "name": "dBPWZ1Z.jpg",
            "slug": "dbpwz1z-jpg",
            "src": "/static/_gallery/albums/animals-cats/832x835_dbpwz1z-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/832x835_dbpwz1z-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/832x835_dbpwz1z-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x501_dbpwz1z-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x802_dbpwz1z-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x501_dbpwz1z-jpg.jpg",
            "width": 832
         },
         {
            "faces": [],
            "height": 914,
            "name": "dvYcWSu.jpg",
            "slug": "dvycwsu-jpg",
            "src": "/static/_gallery/albums/animals-cats/1080x914_dvycwsu-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x866_dvycwsu-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1080x914_dvycwsu-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/499x423_dvycwsu-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x677_dvycwsu-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/499x423_dvycwsu-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 2048,
            "name": "qDupodB.jpg",
            "slug": "qdupodb-jpg",
            "src": "/static/_gallery/albums/animals-cats/1600x1600_qdupodb-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1024_qdupodb-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1600x1600_qdupodb-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x500_qdupodb-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x800_qdupodb-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x500_qdupodb-jpg.jpg",
            "width": 2048
         },
         {
            "faces": [],
            "height": 727,
            "name": "rGzN7t2.jpg",
            "slug": "rgzn7t2-jpg",
            "src": "/static/_gallery/albums/animals-cats/828x727_rgzn7t2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/828x727_rgzn7t2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/828x727_rgzn7t2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x439_rgzn7t2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x702_rgzn7t2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x439_rgzn7t2-jpg.jpg",
            "width": 828
         },
         {
            "faces": [],
            "height": 695,
            "name": "s3yZvyQ.jpg",
            "slug": "s3yzvyq-jpg",
            "src": "/static/_gallery/albums/animals-cats/700x695_s3yzvyq-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/700x695_s3yzvyq-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/700x695_s3yzvyq-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x496_s3yzvyq-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/700x695_s3yzvyq-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x496_s3yzvyq-jpg.jpg",
            "width": 700
         },
         {
            "faces": [],
            "height": 1202,
            "name": "t1S5neO.jpg",
            "slug": "t1s5neo-jpg",
            "src": "/static/_gallery/albums/animals-cats/1129x1202_t1s5neo-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1090_t1s5neo-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1129x1202_t1s5neo-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x532_t1s5neo-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x851_t1s5neo-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x532_t1s5neo-jpg.jpg",
            "width": 1129
         },
         {
            "faces": [],
            "height": 1295,
            "name": "tdIMU0Q.jpg",
            "slug": "tdimu0q-jpg",
            "src": "/static/_gallery/albums/animals-cats/1125x1295_tdimu0q-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1178_tdimu0q-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1125x1295_tdimu0q-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x575_tdimu0q-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x920_tdimu0q-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x575_tdimu0q-jpg.jpg",
            "width": 1125
         },
         {
            "faces": [],
            "height": 652,
            "name": "unknown-319.png",
            "slug": "unknown-319-png",
            "src": "/static/_gallery/albums/animals-cats/652x652_unknown-319-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/652x652_unknown-319-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/652x652_unknown-319-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x500_unknown-319-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/652x652_unknown-319-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x500_unknown-319-png.png",
            "width": 652
         },
         {
            "faces": [],
            "height": 1383,
            "name": "yWBSAv9.jpg",
            "slug": "ywbsav9-jpg",
            "src": "/static/_gallery/albums/animals-cats/1242x1383_ywbsav9-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/1024x1140_ywbsav9-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/1242x1383_ywbsav9-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x556_ywbsav9-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/800x890_ywbsav9-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x556_ywbsav9-jpg.jpg",
            "width": 1242
         },
         {
            "faces": [],
            "height": 948,
            "name": "zKLK5Mc.png",
            "slug": "zklk5mc-png",
            "src": "/static/_gallery/albums/animals-cats/700x948_zklk5mc-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/700x948_zklk5mc-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/700x948_zklk5mc-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x677_zklk5mc-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/700x948_zklk5mc-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x677_zklk5mc-png.png",
            "width": 700
         },
         {
            "faces": [],
            "height": 1190,
            "name": "zLI0cFA.jpg",
            "slug": "zli0cfa-jpg",
            "src": "/static/_gallery/albums/animals-cats/700x1190_zli0cfa-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals-cats/700x1190_zli0cfa-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals-cats/700x1190_zli0cfa-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals-cats/500x850_zli0cfa-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals-cats/700x1190_zli0cfa-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals-cats/500x850_zli0cfa-jpg.jpg",
            "width": 700
         }
      ],
      "slug": "animals-cats",
      "src": "/static/_gallery/albums/animals-cats/500x645_6ka6cml-jpg.jpg"
   },
   "screenshots": {
      "name": "Screenshots",
      "photos": [
         {
            "faces": [],
            "height": 1080,
            "name": "gtfo-out-of-map.jpg",
            "slug": "gtfo-out-of-map-jpg",
            "src": "/static/_gallery/albums/screenshots/1600x900_gtfo-out-of-map-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots/1024x576_gtfo-out-of-map-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots/1600x900_gtfo-out-of-map-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots/500x281_gtfo-out-of-map-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots/800x450_gtfo-out-of-map-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots/500x281_gtfo-out-of-map-jpg.jpg",
            "width": 1920
         },
         {
            "faces": [],
            "height": 623,
            "name": "voicemeeter-setup.png",
            "slug": "voicemeeter-setup-png",
            "src": "/static/_gallery/albums/screenshots/1025x623_voicemeeter-setup-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots/1024x622_voicemeeter-setup-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots/1025x623_voicemeeter-setup-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots/500x303_voicemeeter-setup-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots/800x486_voicemeeter-setup-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots/500x303_voicemeeter-setup-png.png",
            "width": 1025
         }
      ],
      "slug": "screenshots",
      "src": "/static/_gallery/albums/screenshots/500x281_gtfo-out-of-map-jpg.jpg"
   },
   "screenshots-no-mans-sky": {
      "name": "screenshots > No Mans Sky",
      "photos": [
         {
            "faces": [],
            "height": 1080,
            "name": "Apr-15@22.55.28.png",
            "slug": "apr-15-22-55-28-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_apr-15-22-55-28-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_apr-15-22-55-28-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_apr-15-22-55-28-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_apr-15-22-55-28-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_apr-15-22-55-28-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_apr-15-22-55-28-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-1@2.35.29.png",
            "slug": "oct-1-2-35-29-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-1-2-35-29-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-1-2-35-29-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-1-2-35-29-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-1-2-35-29-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-1-2-35-29-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-1-2-35-29-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-3@2.0.12.png",
            "slug": "oct-3-2-0-12-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-3-2-0-12-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-3-2-0-12-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-3-2-0-12-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-3-2-0-12-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-3-2-0-12-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-3-2-0-12-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-4@18.53.4.png",
            "slug": "oct-4-18-53-4-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-4-18-53-4-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-4-18-53-4-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-4-18-53-4-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-4-18-53-4-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-4-18-53-4-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-4-18-53-4-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-5@16.2.58.png",
            "slug": "oct-5-16-2-58-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-5-16-2-58-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-5-16-2-58-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-5-16-2-58-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-5-16-2-58-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-5-16-2-58-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-5-16-2-58-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-5@2.17.42.png",
            "slug": "oct-5-2-17-42-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-5-2-17-42-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-5-2-17-42-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-5-2-17-42-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-5-2-17-42-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-5-2-17-42-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-5-2-17-42-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-6@16.40.47.png",
            "slug": "oct-6-16-40-47-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-6-16-40-47-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-6-16-40-47-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-6-16-40-47-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-6-16-40-47-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-6-16-40-47-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-6-16-40-47-png.png",
            "width": 1920
         },
         {
            "faces": [],
            "height": 1080,
            "name": "Oct-6@22.51.25.png",
            "slug": "oct-6-22-51-25-png",
            "src": "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-6-22-51-25-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1024x576_oct-6-22-51-25-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/1600x900_oct-6-22-51-25-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-6-22-51-25-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-no-mans-sky/800x450_oct-6-22-51-25-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_oct-6-22-51-25-png.png",
            "width": 1920
         }
      ],
      "slug": "screenshots-no-mans-sky",
      "src": "/static/_gallery/albums/screenshots-no-mans-sky/500x281_apr-15-22-55-28-png.png"
   },
   "screenshots-terraria": {
      "name": "screenshots > Terraria",
      "photos": [
         {
            "faces": [],
            "height": 399,
            "name": "gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria.png",
            "slug": "gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png",
            "src": "/static/_gallery/albums/screenshots-terraria/890x399_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-terraria/890x399_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-terraria/890x399_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-terraria/500x224_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-terraria/800x358_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-terraria/500x224_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png",
            "width": 890
         },
         {
            "faces": [],
            "height": 1080,
            "name": "terraria-5P-6iewtFS8.jpg",
            "slug": "terraria-5p-6iewtfs8-jpg",
            "src": "/static/_gallery/albums/screenshots-terraria/1600x900_terraria-5p-6iewtfs8-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-terraria/1024x576_terraria-5p-6iewtfs8-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-terraria/1600x900_terraria-5p-6iewtfs8-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-terraria/500x281_terraria-5p-6iewtfs8-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-terraria/800x450_terraria-5p-6iewtfs8-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-terraria/500x281_terraria-5p-6iewtfs8-jpg.jpg",
            "width": 1920
         },
         {
            "faces": [],
            "height": 727,
            "name": "terraria-building.png",
            "slug": "terraria-building-png",
            "src": "/static/_gallery/albums/screenshots-terraria/1357x727_terraria-building-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-terraria/1024x548_terraria-building-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-terraria/1357x727_terraria-building-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-terraria/500x267_terraria-building-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-terraria/800x428_terraria-building-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-terraria/500x267_terraria-building-png.png",
            "width": 1357
         },
         {
            "faces": [],
            "height": 736,
            "name": "terraria-building2.png",
            "slug": "terraria-building2-png",
            "src": "/static/_gallery/albums/screenshots-terraria/1367x736_terraria-building2-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-terraria/1024x551_terraria-building2-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-terraria/1367x736_terraria-building2-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-terraria/500x269_terraria-building2-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-terraria/800x430_terraria-building2-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-terraria/500x269_terraria-building2-png.png",
            "width": 1367
         },
         {
            "faces": [],
            "height": 1050,
            "name": "terraria-house-QZwdi9m.png",
            "slug": "terraria-house-qzwdi9m-png",
            "src": "/static/_gallery/albums/screenshots-terraria/1600x1000_terraria-house-qzwdi9m-png.png",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/screenshots-terraria/1024x640_terraria-house-qzwdi9m-png.png"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/screenshots-terraria/1600x1000_terraria-house-qzwdi9m-png.png"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/screenshots-terraria/500x312_terraria-house-qzwdi9m-png.png"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/screenshots-terraria/800x500_terraria-house-qzwdi9m-png.png"
               ]
            },
            "thumb": "/static/_gallery/albums/screenshots-terraria/500x312_terraria-house-qzwdi9m-png.png",
            "width": 1680
         }
      ],
      "slug": "screenshots-terraria",
      "src": "/static/_gallery/albums/screenshots-terraria/500x224_gratis-png-sala-de-videojuegos-de-terraria-casa-edificio-cuatro-pilares-terraria-png.png"
   }
};